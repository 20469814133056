import { SEO } from 'site/src/components/legacy/mol.seo/seo.component'
import TitleWithBreadcrumbRow from 'site/src/components/org.title-with-breadcrumb-row/title-with-breadcrumb-row.component'
import { PageProps } from 'site/src/utils/local-types'
import { appPaths, getCanonicalUrl, getPathUrl } from 'utils/path'
import { getSearchQueryParam, isDoctorUrl } from 'utils/url'
import LargeSeparatorRow from 'atomic/atm.large-separator-row/large-separator-row.component'
import IndexLayout from 'site/src/components/org.layout/layout.component'
import {
  CosmicjsInformacoesEstaticas,
  Query
} from 'site/src/data/graphql/graphql-types'
import { Body, Button, Col, DD, DT, Grid, Hbox, Row, Separator } from 'atomic'
import { Badge } from 'atomic/legacy/atm.badge/badge.component'
import { LazyLoadImage } from 'atomic/legacy/atm.lazy-load-image'
import { graphql, Link } from 'gatsby'
import React from 'react'
import { SpecialistImageWrapper } from './component/specialist-image.style'

export interface OurSpecialistsDetailTemplateProps {
  slug: string
}

interface PageData {
  title: string
  aboutHtml: string
  email: string
  imgixSrc: string
  imgAlt: string
  specialties: string[]
}
interface StaticInfo {
  academicFormationItemTitle: string
  emailItemTitle: string
  specialtyItemTitle: string
}

class OurSpecialistsDetailTemplate extends React.Component<
  PageProps<OurSpecialistsDetailTemplateProps, Query>
> {
  render() {
    const data = this.props.data.cosmicjsCorpoClinico
    const ourSpecialistListInfo = (this.props.data as any)
      .ourSpecialistListInfo as CosmicjsInformacoesEstaticas

    const breadcrumbDictionary = {}
    breadcrumbDictionary[`/${this.props.pageContext.slug}`] = data.title

    const staticData: StaticInfo = {
      academicFormationItemTitle: 'Formação Acadêmica:',
      emailItemTitle: 'E-mail:',
      specialtyItemTitle: 'Especialidade:'
    }

    const pageData: PageData = {
      title: data.title,
      aboutHtml: data.metadata.descricaoCompleta,
      imgixSrc:
        data.metadata.foto.imgix_url ||
        ourSpecialistListInfo.metadata.iconePlaceholder.imgix_url,
      email: data.metadata.email,
      imgAlt: `Foto do especialista: ${data.title}`,
      specialties: (data.metadata.especialidade || []).map(
        especialidade => especialidade.title
      )
    }

    const fleuryLogo = this.props.data.cosmicjsInformacoesEstaticas.metadata.seo
      .imagem.url

    return (
      <IndexLayout location={this.props.location}>
        <SEO
          socialMedia={{
            canonicalUrl: getCanonicalUrl(
              this.props.data.site.siteMetadata.siteUrl,
              appPaths.ourSpecialists.path,
              this.props.pageContext.slug
            ),
            title: data.metadata.seo.titulo,
            image:
              (data.metadata.seo.imagem && data.metadata.seo.imagem.url) ||
              fleuryLogo,
            imageAlt: data.metadata.seo.imagemAlternativa,
            description: data.metadata.seo.descricao
          }}
        />
        <Grid>
          <TitleWithBreadcrumbRow
            title={pageData.title}
            addtionalDictionary={breadcrumbDictionary}
          />
          <LargeSeparatorRow />
          <Row>
            <Col xsOffset={2} xs={8} smOffset={3} sm={6} mdOffset={0} md={3}>
              <SpecialistImageWrapper>
                <LazyLoadImage
                  src={pageData.imgixSrc}
                  alt={pageData.imgAlt}
                  fitWidth
                  aspectRatio={1}
                />
              </SpecialistImageWrapper>
              <Separator />
            </Col>
            <Col xs={12} md={9}>
              <Row mb>
                <Col xs={12} mdOffset={0} md={12}>
                  <Body>
                    <div
                      dangerouslySetInnerHTML={{ __html: pageData.aboutHtml }}
                    />
                  </Body>
                </Col>
              </Row>
              {pageData.email && (
                <Row mb>
                  <Col>
                    <DT>{staticData.emailItemTitle}</DT>
                  </Col>
                  <Col xs={12} md={9}>
                    <DD>
                      <Button
                        id='button-our-specialists'
                        kind='link'
                        external
                        to={`mailto:${pageData.email}`}
                      >
                        {pageData.email}
                      </Button>
                    </DD>
                  </Col>
                </Row>
              )}
              {pageData.specialties[0] && (
                <Row mb middle='xs'>
                  <Col>
                    <Hbox>
                      <DT>{staticData.specialtyItemTitle}</DT>
                    </Hbox>
                  </Col>
                  <Col xs={12} md={9}>
                    <DD>
                      <Row>
                        {pageData.specialties.sort().map(speciality => (
                          <Col>
                            <Link
                              to={`${getPathUrl(
                                appPaths.search.path,
                                isDoctorUrl(this.props.location.pathname)
                              )}${getSearchQueryParam(speciality)}`}
                            >
                              <Badge text={speciality} kind='secondary' />
                            </Link>
                          </Col>
                        ))}
                      </Row>
                    </DD>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
          <LargeSeparatorRow />
        </Grid>
      </IndexLayout>
    )
  }
}

export default OurSpecialistsDetailTemplate

export const query = graphql`
  query OurSpecialistsDetailBySlug($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }

    cosmicjsInformacoesEstaticas(slug: { eq: "informacoes-de-contato" }) {
      metadata {
        seo {
          imagem {
            url
          }
        }
      }
    }

    ourSpecialistListInfo: cosmicjsInformacoesEstaticas(
      slug: { eq: "corpo-clinico-lista" }
    ) {
      metadata {
        iconePlaceholder {
          imgix_url
        }
      }
    }

    cosmicjsCorpoClinico(slug: { eq: $slug }) {
      id
      title
      metadata {
        email
        foto {
          url
          imgix_url
        }
        descricaoSimples
        descricaoCompleta
        especialidade {
          title
        }
        seo {
          titulo
          imagemAlternativa
          imagem {
            url
            imgix_url
          }
          descricao
        }
      }
    }
  }
`
